import * as yup from 'yup';
import { validar } from '~/utils/ie';

export const schema = yup.object({
  cod_loja: yup.number().min(0).required(),
  num_cnpj: yup
    .string()
    .required()
    .test((value) => {
      // Remove os caracteres especiais e verifica possui 11 ou 14 digitos
      value = value?.replace(/\D/g, '');
      return value?.length === 14;
    }),
  nascimentoAbertura: yup.string().required(),
  inativo: yup.boolean(),
  name: yup.string().required(),

  tipo_loja: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),

  apelido: yup.string().required(),
  email: yup.string().email().notRequired().nullable(true),
  num_ie: yup.string().notRequired(),
  // num_ie: yup
  //   .string()
  //   .required()
  //   .test(function (value) {
  //     // chama funcao para validar IE com base no estado escolhido
  //     const estados = this.parent?.estados;

  //     if (!value) return false;

  //     if (!estados) {
  //       return true;
  //     }
  //     if (estados) {
  //       if (!estados.label) return true;

  //       return validar(value, estados.label);
  //     }

  //     return false;
  //   }),
  cep: yup.string().required(),
  logradouro: yup.string().required(),
  numero: yup.string().required(),
  bairro: yup.string().required(),
  cidades: yup
    .object()
    .shape({
      value: yup.number().required(),
      ufValue: yup.string().notRequired(),
      label: yup.string().required(),
    })
    .required(),
});

export const condicao = yup
  .object({
    condicaoFinalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    numCondicao: yup.number().required(),
  })
  .required();
